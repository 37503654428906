<template>
  <div class="data">
    <div class="row" v-if="user">
        <label for="email" class="grid--title" v-if="user.is_secure == 0"><span class="grid--span">*</span>Escanea el QR en Google Autenticador y digita el código de seguridad proporcionado</label >
        <qrcode-vue class="mt-3" style="margin:auto; margin-bottom: 20px" v-if="user.is_secure == 0" :value="user.otpauth_url" size="210" level="L"/>
        <div class="mb-3" v-if="user.is_secure == 0">
            <Copy :address="separateSecret(user.otpauth_url)" />
        </div>
        <div class="grid--item">

            <b-form-input v-if="oauth == true" v-model="form.mobile" placeholder="Enter your Oauth Code" />

            <b-button size="lg" variant="primary" class="w-100 mt-3" v-if="oauth == true" @click="checkCodeMobile()" :disabled="validateMobile == true">
                <span v-if="validateMobile == false">Validate Oauth Code</span>
                <span v-else>Validated</span>
            </b-button>

            <b-form-input v-if="email == true" v-model="form.code" placeholder="Enter your email Code" />

            <b-button size="lg" variant="primary" class="w-100 mt-3" v-if="email == true" @click="checkCodeEmail()" :disabled="validateEmail == true">
                <span v-if="validateEmail == false">Validate Email Code</span>
                <span v-else>Validated</span>
            </b-button>

        </div> 
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import QrcodeVue from 'qrcode.vue'

export default {
    props: {
        oauth: {
            default: false
        },
        email: {
            default: false
        },
        size: {
            default: 'md'
        }
    },
    components: {
      QrcodeVue
    },
    data () {
        return {
            loading: false,
            globalValidation: false,
            validateEmail: false,
            validateMobile: false,
            form: {
                mobile: null,
                code: null
            },
            oauthCode: null
        }
    },
    created () {
        console.log("CARGADO")
        this.getUserInfo()
        if(this.email == true) {
            this.sendCodeEmail()
        }
        if(this.oauth == true && this.user.otpauth_url == null) {
            this.sendCodeMobile()
        }
    },
    methods: {
        ...mapActions('auth', ['getUserInfo', 'getOauthCode', 'validateCodeMobile', 'sendValidationEmail', 'validateCodeEmail']),
        sendCodeEmail() {
          this.loading = true
          this.sendValidationEmail().then(() => {
              this.sended = true
              this.loading = false
          })
        },
        checkCodeEmail () {
            this.loading = true
            this.validateCodeEmail(this.form).then(() => {
                openNotification('Código por correo validado correctamente')
                this.validateEmail = true
                this.loading = false
            })
            this.loading = false
        },
        sendCodeMobile() {
          this.loading = true
          console.log("VALIDATION", this.user.otpauth_url, this.oauth)
          this.getOauthCode().then(response => {
              this.getUserInfo()
              this.sended = true
              this.loading = false
          })
        },
        checkCodeMobile () {
            this.loading = true
            this.validateCodeMobile(this.form).then(() => {
                this.validateMobile = true
                openNotification('Autenticador de google validado correctamente')
                this.getUserInfo()
                this.loading = false
            })
            this.loading = false
        },
        separateSecret (url) {
            let str = url.split('secret=')
            return str[1]
        }
    },
    computed: {
        ...mapState('auth', ['user'])
    },
    watch: {
        validateMobile: function (data) {
            if(this.oauth == true && this.email == true) {
                if(this.validateMobile == true && this.validateEmail == true) {
                    this.$emit('validated', true)
                }
            }
            if(this.oauth == true && this.email == false) {
                if(this.validateMobile == true) {
                    this.$emit('validated', true)
                }

            }
        },
        validateEmail: function (data) {
            console.log("HO")
            if(this.oauth == true && this.email == true) {
                if(this.validateMobile == true && this.validateEmail == true) {
                    this.$emit('validated', true)
                }
            }
            if(this.email == true && this.oauth == false) {
                console.log("HOLA", this.validateEmail)
                if(this.validateEmail == true) {
                    console.log("HOLI", this.validateEmail)
                    this.$emit('validated', true)
                }

            }
        }
    }
}
</script>
<style scoped>
    .data{
        text-align: left;
    }
</style>