import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'

import '@/assets/css/navbar.css'
import '@/assets/css/sidebar.css'
import '@/assets/css/styles.css'
import '@/assets/css/bootstrap.css'

import globalComponent from './plugins/global-components'
import BootstrapVue3 from 'bootstrap-vue-3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import vueCountryRegionSelect from 'vue3-country-region-select'
import Toaster from '@meforma/vue-toaster';

import VueApexCharts from "vue3-apexcharts";

const app = createApp(App)

app.use(VueApexCharts);
app.use(router)
app.use(store)
app.use(globalComponent)
app.use(BootstrapVue3)
app.use(Toaster)
app.use(vueCountryRegionSelect)

app.mount('#app')

// success,danger
window.openNotification = function (text = 'Transaction executed successfully', variant = 'success') {

    app.$toast.show(text,
        {
            type: variant,
            position: "top-right",
            duration: 2000,
        }
    )
}
