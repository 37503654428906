import axios from 'axios'

export default {
  namespaced: true,
  state: {
    stakings: []
  },
  getters: {},
  mutations: {
    SET_STAGE (state, data) {
        state.stakings.unshift(data)
    },
    GET_STAGE (state, data) {
      state.stakings = data
    },
    GET_STAGE_ACTIVE (state, data) {
      state.active = data
    },
    UPDATE_STAGE (state, data) {
        let objIndex = state.stakings.findIndex((obj => obj.id == data.id));
        state.stakings[objIndex] = data
    },
    REMOVE_STAGE (state, id) {
        const indexOfObject = state.stakings.findIndex(object => {
           return object.id === id;
        });
        state.stakings.splice(indexOfObject)
    }
  },
  actions: {
    async getStakings (context) {
        const response = await axios.get('/api/v1/staking')
        context.commit('GET_STAGE', response.data)
        return response.data
    },
    async storeStaking (context, data) {
        const response = await axios.post('/api/v1/staking', data)
        context.commit('SET_STAGE', response.data)
        return response.data
    }
  }
}
