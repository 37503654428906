import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'App',
    component: () => import('@/layouts/App.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Index'),
      },
      {
        path: 'wallet',
        name: 'Wallet',
        component: () => import('@/views/wallet/Index'),
      },
      {
        path: 'staking',
        name: 'Staking',
        component: () => import('@/views/staking/Index'),
      },
      {
        path: 'create-staking',
        name: 'Create-Staking',
        component: () => import('@/views/staking/create/Index'),
      },
      {
        path: 'p2p',
        name: 'P2P',
        component: () => import('@/views/pp/Index'),
      },
      {
        path: 'chat',
        name: 'Chat',
        component: () => import('@/views/chats/Index'),
      },
      {
        path: 'support',
        name: 'Support',
        component: () => import('@/views/support/Index'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/profile/Index'),
      }
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        path: 'signin',
        name: 'Signin',
        component: () => import('@/views/auth/ScanQr'),
      },
      {
        path: 'signup/:username?',
        name: 'Signup',
        component: () => import('@/views/auth/Signup'),
        props: true
      },
      {
        path: 'recover-password',
        name: 'RecoverPassword',
        component: () => import('@/views/auth/RecoverPassword'),
      },
      {
        path: 'recover/:id/:hash',
        name: 'RestorePassword',
        component: () => import('@/views/auth/RestorePassword'),
        props: true
      },
      {
        path: 'validation',
        name: 'Validation',
        component: () => import('@/views/auth/Validation')
      }
    ]
  },
  {
    path: '/manage',
    name: 'Manage',
    component: () => import('@/layouts/Manteinance.vue'),
    children: [
      {
        path: '',
        name: 'Manage-Dashboard',
        component: () => import('@/views/dashboard/Index'),
      },
      {
        path: 'membership',
        name: 'Manage-Membership',
        component: () => import('@/views/manage/membership/Index'),
      },
      {
        path: 'tokens',
        name: 'Manage-Tokens',
        component: () => import('@/views/manage/tokens/Index'),
      },
      {
        path: 'other',
        name: 'Manage-Other',
        component: () => import('@/views/dashboard/Index'),
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = store.state.auth.user

  console.log('AUTH USER', loggedIn, to.path)

  /* to.name !== 'login' &&  */

  const publicPages = ['Signin', 'RecoverPassword', 'Signup', 'RestorePassword', 'Validation']

  console.log('RUTA', to.path, to.name)

  const authRequired = !publicPages.includes(to.name)

  if (authRequired && loggedIn === null) {
    next({
      path: '/auth/signin',
      replace: true
    })
    next()
  } else {
    next()
  }
})

export default router
