<template>
    <div class="code-container mt-3">
        <div class="code-centered text-center">
            <section class="register__content text-center mb-3" v-if="user">
                <h4 style="color: white" v-if="user.pin != null">Enter the validation pin to continue</h4>
                <h4 style="color: white" v-else>
                    Create your pin, we will request to login
                </h4>
            </section>
            
            <b-form-input type="password" v-model="form.pin"/>

            <ButtonAction class="mt-4" lg="true" block="true" :loading="$store.state.loading == true" loadingTx="Validating..." title="Validate pin" @click="onSubmit()"/>                
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    data () {
        return {
            form: {
                pin: null
            },
        }
    },
    created () {
        this.getUserInfo()
    },
    methods: {
        ...mapActions('auth', ['validateCodePin', 'getUserInfo']),
        onSubmit() {
            this.validateCodePin(this.form).then(() => {
                openNotification('Validado correctamente')
                this.$emit('validated')
            })
        },
        concatPin (data) {
            if(this.form.pin != null) {
                if(this.form.pin.length > 3) {
                    openNotification('Solo se permiten 4 digitos', 'negative')
                    return
                }

                this.form.pin = this.form.pin.toString() + data.toString()
            } else {
                this.form.pin = data.toString()
            }
        }
    },
    computed: {
        ...mapState('auth', ['user'])
    }
}
</script>
<style scoped>
.code-container{
position: relative;
}

    .input-code{
        width: 40%;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 12px;
        background-color: transparent;
        opacity: 1;
        font-size: 40px;
        color: white !important;
        text-align: center;
    }
    .buttons{
        width: 90%;
        margin: auto;
    }
    .buttons .col-4{
        padding: 5px;
        text-align: center;
        margin-bottom: 10px;
    }
    .buttons .button {
        background-color: rgba(51, 106, 179, 0.3);
        border-radius: 50px;
        color: white;
        font-size: 20px;
        width: 60px;
        height: 60px;
        padding: 13px 20px;
        margin: auto;
    }
    .sBtn{
        width: 80%;
        margin: auto;
    }
</style>