<template>
    <div class="copy-section bg-secondary row" :class="colored == 'true' ? 'colored-card' : '' ">
        <div class="col-10">
            {{address}}
        </div>
        <div class="col-2 click text-right" @click="copyURL(address)">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="7.49805" y="6.99805" width="14.0058" height="14.0058" rx="2" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.49776 17.0022H5.49693C4.3919 17.0022 3.49609 16.1064 3.49609 15.0013V4.99717C3.49609 3.89214 4.3919 2.99634 5.49693 2.99634H15.5011C16.6061 2.99634 17.5019 3.89214 17.5019 4.99717V6.998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        address:{
            required: true
        },
        colored: {
            default: false
        }
    },
    methods: {
        async copyURL (mytext) {
            try {
                await navigator.clipboard.writeText(mytext)
                openNotification('Coppied to clipboard')
            } catch ($e) {
                openNotification('Please try again', 'error')
            }
        }
    }
}
</script>
<style>
    .copy-section{
        margin: auto;
        border: 1px dashed white;
        padding: 10px;
        border-radius: 16px;
        width: 95%;
    }
    .colored-card{
        background: linear-gradient(256.2deg, #2D0D7C 0%, #3A0C4C 97.56%) !important;
        color: white !important;
    }

</style>