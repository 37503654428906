import { createStore } from 'vuex'

import auth from './modules/auth'
import interceptors from './modules/interceptors'
import membership from './modules/membership'
import wallet from './modules/wallet'
import chat from './modules/chat'
import cart from './modules/cart'
import user from './modules/user'
import staking from './modules/staking'
import support from './modules/support'

import vuexPersistence from '../plugins/vue-persistence'

import axios from 'axios'
axios.defaults.baseURL = 'https://elvwalletapi.com/'
/* axios.defaults.baseURL = 'http://127.0.0.1:3000' */

export default createStore({
  state: {
    appName: 'Eost Wallet',
    loading: false
  },
  modules: {
    auth,
    interceptors,
    membership,
    wallet,
    chat,
    cart,
    user,
    staking,
    support
  },
  plugins: [vuexPersistence.plugin]
})

const vuex = JSON.parse(localStorage.getItem('vuex'))
if (vuex) {
  if (vuex.auth) {
    if (vuex.auth.access_token) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + vuex.auth.access_token
      axios.defaults.headers.common['DEVICEID'] = vuex.auth.code
    }
  }
}
